import { useEffect, useState } from "react"
import checkmark from "../../assets/images/checkmark.png"
import { Link } from "react-router-dom"
import { AppStoreRating } from "../rating/AppStoreRating"
import { OurRating } from "../rating/OurRating"
import { providersList } from "../../utils/helpers"

interface CansListProps {
  type?: string
  state?: string
  cans: any[]
  provider?: string
}

export function CansList({ state, type, cans, provider }: CansListProps) {
  const [data, setData] = useState(cans)
  useEffect(() => {
    let finalList = data
    if (state) {
      finalList = data.filter((item) => item.state.slug == state)
    }
    if (type) {
      finalList = finalList.filter((item) => item.type == type.toUpperCase())
    }

    finalList = finalList.filter((item) => item.status)
    finalList.sort((a, b) => a.sortOrder - b.sortOrder)
    setData(finalList)
  }, [type, cans, state])
  return (
    <div>
      {data.map((item) => {
        const fileId = item.file ? item.file.id : 0
        const image_url = item.file
          ? `${import.meta.env.VITE_STATIC_SERVER}${item.file.filename}`
          : ""
        return (
          <div className="card-item" key={item.id}>
            {/* <div className="checkmark">
              <img src={checkmark} alt="" />
            </div> */}
            <div className="card-item-img">

              <div className="image object-fit">
                <img className="img-fluid img-1" src={image_url} alt="" />
              </div>
              {providersList.includes(provider? provider : "") ? (
                <OurRating value={item.rating} />
              ) : (
                <AppStoreRating value={item.rating} />
              )}
              <div className="heading-mobile">
                {fileId == 62 ? (
                  <>
                    <h4>
                      DraftKings Ontario distinguishes itself with its top-rated
                      app, offering stellar lines and odds boosts that enhance
                      the the betting experience in Ontario. Check out what
                      DraftKings has to offer below.
                    </h4>
                    <ul>
                      <li>
                        Market-leading sports product, especially for key
                        Canadian sports
                      </li>
                      <li>Dozens of exclusive DraftKings titles</li>
                      <li>
                        User-friendly interface and robust customer support
                      </li>
                      <li>800+ online casino games</li>
                      <li>Competitive Odds</li>
                    </ul>
                  </>
                ) : (
                  <h4>{item.description}</h4>
                )}
              </div>
            </div>
            <div className="content-info">
              <div className="card-item-payment">
                {fileId == 62 ? (
                  <>
                    <h4>
                      DraftKings Ontario distinguishes itself with its top-rated
                      app, offering stellar lines and odds boosts that enhance the
                      the betting experience in Ontario. Check out what DraftKings
                      has to offer below.
                    </h4>
                    <ul>
                      <li>
                        Market-leading sports product, especially for key Canadian
                        sports
                      </li>
                      <li>Dozens of exclusive DraftKings titles</li>
                      <li>User-friendly interface and robust customer support</li>
                      <li>800+ online casino games -Competitive Odds</li>
                    </ul>
                  </>
                ) : (
                  <h4>{item.description}</h4>
                )}
              </div>
              <Link to={item.url} target={"_blank"} className="card-item-claim">
                Play Now
              </Link>
            </div>
          </div>
        )
      })}
    </div>
  )
}
