import { NavLink } from "react-router-dom"

interface StatesProps {
  states: any[]
  provider: string
}

export const States = ({ states, provider }: StatesProps) => {
  return (
    <>
      <div className="location-options">
        <div>
          <div className="text-center ">
            <h4 className="sub-heading-location">Where are you located ?</h4>
          </div>
          <div className="btns">
            {states.map((state) => {
              return (
                <div  key={state.label} className="option">
                  <NavLink
                    to={`/${provider}/${state.slug}`}
                    className="btnPlayCan"
                  >
                    {state.label}
                  </NavLink>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
